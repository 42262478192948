<template>
   <v-theme-provider>
    <base-section
      id="news-latest"
      class="primary lighten-3 mt-n6"
      space="0"
    >
    <div 
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-anchor-placement="top-center">
    <v-container
    >
      <v-row
        no-gutters
      >
        <v-col
          v-for="item in items"
          :key="item.icon"
          cols="12"
          xs="12"
          md="3"
          sm="6"
        >
          <v-card
            outlined
            tile
            height = "180"
            :class="item.class"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
              <v-avatar
                class="ma-2"
                size="125"
                tile
                v-if="item.src"
                height="90%"
              >
                <v-img :src="require('../../../src/assets/'+item.src)" class="rounded-xl"></v-img>
              </v-avatar>
              </div>
              <div>
                <v-card-title v-if="item.title"><span><v-icon x-large color="fourth" v-if="item.icon !== 'mdi-package'">{{item.icon}}</v-icon> <span class="text-h5 font-weight-bold" v-html="item.title"></span></span></v-card-title>
                <v-card-subtitle class="body-2 ml-n3 mx-auto font-weight-bold" v-if="item.icon !== 'mdi-package'">{{item.text}}</v-card-subtitle>
                <v-card-subtitle class="caption" v-else>{{item.text}}</v-card-subtitle>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
  </base-section>
</v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      items: [
        {
          icon: 'mdi-package',
          title: 'Kenapa Harus<br>ISTPRO?',
          text: 'Broker Asuransi berpengalaman, menyediakan berbagai produk asuransi sesuai dengan kebutuhan anda',
          class: 'transparent',
          src: '',
        },
        {
          icon: 'mdi-professional-hexagon',
          title: '',
          text: 'Proses cepat dan mudah',
          class: 'white rounded-lg mx-5',
          src: 'why-icon-1.png',
        },
        {
          icon: 'mdi-clock-fast',
          title: '',
          text: 'Biaya terjangkau sesuai kebutuhan',
          class: 'white rounded-lg mx-5',
          src: 'why-icon-2.png',
        },
        {
          icon: 'mdi-lock-check',
          title: '',
          text: 'Bekerjasama dengan ratusan perusahaan Asuransi',
          class: 'white rounded-lg mx-5',
          src: 'why-icon-3.png',
        },
      ],
    }),
  }
</script>